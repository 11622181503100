/* ------------------------------------------------------------- */

/* Media css */

.spinner-container {
    height: 100vh;
    display: flex;
}

span.loader {
    width: fit-content;
    margin: auto;
    /* margin: 15% auto; */
}

span.loader>span {
    width: 50px !important;
    height: 50px !important;
}

.media h1 {
    color: #0f3ea1;
    font-weight: bold;
    padding-left: 5%;
    margin: auto;
}

.media .section-1 {
    padding-top: 10vh;
}

.media .section-1 img {
    width: 100vw;
    height: 90vh;
}

.media .section-2 {
    background-image: url('../assets/white-bg.png');
}

.media .section-2 h1 {
    padding-top: 40px;
}

.media .section-2 .main-row {
    padding: 30px 4%;
    margin: auto;
    max-height: 640px;
}

.media .section-2 .main-row .col-6 {
    margin-bottom: 10px;
}

.media .section-2 .main-row .image-container {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 250px;
}

.media .section-2 .image-container .after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 10px;
    background: rgba(255, 255, 255, .5);
    transition: 0.3s;
}

.media .section-2 .image-container:hover .after {
    display: block;
    background: transparent;
}

.media .section-2 .row .iframe1,
.media .section-2 .row .launch-img {
    width: 100%;
    height: 100%;
    max-height: 250px;
    border-radius: 10px;
}

.media .section-2 .row .middle-iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.media .section-2 .row .col {
    margin: 10px;
}

.media .section-2 .advertisement {
    padding: 30px 4%;
    margin: auto;
}

.media .section-2 .advertisement .image-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.media .section-2 .advertisement .side-img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.media .section-2 .advertisement .add-img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
}




@media (max-width: 990px) {
    .media .section-1 img {
        height: 35vh;
    }

    .media .section-2 .advertisement .col-12:nth-child(1) {
        margin-bottom: 20px;
    }
}

/* ------------------------- */