/* home-screen */
.home-screen {
    height: 100vh;
}
#myVideo {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    left: 0;
    position: absolute;
    top: 0;
    z-index: -1;
}
.home-screen-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;
}
.home-inner-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.home-inner-content h5{
    font-size: 1.2rem;
    font-weight: 400;
}

@media only screen and (max-width: 769px) {
    .home-inner-content h5 {
        font-size: calc(0.75rem + 0.8vw);
    }
}
/* home-screen */



/* ********* */
/* advantage */
/* ********* */
.advantage {
    background-image: url('../assets/blue\ bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90vh;
}

.rudra-advantage-title {
    padding-top: 6rem;
}

.content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
/* .advantage .upper-row {
    margin-bottom: 3.5rem;
} */
/* .advantage .lower-row {
    margin-top: 3.5rem;
} */
.advantage .advantage-points {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.advantage .outer-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 4.7px #6d5c49;
    margin: 20px 5px;
}
.advantage .inner-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--primary-yellow);
}
.advantage-mobile-rod {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* when screen size is less than 768px */
@media only screen and (max-width: 768px) {
    .advantage {
        background-image: url('../assets/mobile_rod.png');
        background-size: 100% 100%;
        justify-content: center;
        height: auto;
        /* display: block; */
    }

    .rudra-advantage-title {
        padding-bottom: 3rem;
        padding-top: 0;
    }
    .advantage .advantage-main-row {
        align-items: center;
    }
    .advantage .advantage-points {
        flex-direction: row;
        font-size: calc(0.75rem + 0.8vw);
        justify-content: flex-end;
    }
    /* .advantage .upper-row {
        margin-bottom: 0;
    }
    .advantage .lower-row {
        margin-top: 0;
    } */
    .advantage .upper-row .advantage-points {
        text-align: end;
    }
    .advantage .lower-row .advantage-points{
        justify-content: flex-start;
    }
    .advantage .upper-row .advantage-points .outer-circle {
        margin-left: 1rem;
    }
    .advantage .lower-row .advantage-points .outer-circle {
        margin-right: 1rem;
    }
    .advantage-mobile-rod {
        display: flex;
    }
    .advantage-mobile-rod .rod_image {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .advantage .upper-row {
        margin-bottom: 0;
    }
    .advantage .lower-row {
        margin-top: 0;
    }
}

@media only screen and (width: 768px) {
    .advantage {
        background-image: url('../assets/blue\ bg.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        justify-content: center;
        align-items: center;
    }
    .advantage .advantage-points {
        flex-direction: column;
    }
    .advantage-mobile-rod .rod_image {
        display: flex;
    }
    .advantage-main-row {
        padding-bottom: 3rem;
    }
}
@media only screen and (max-width: 576px) {
    .advantage {
        background-size: cover;
    }
}
/* 
@media only screen and (width: 769px) {
    .advantage .advantage-points {
        flex-direction: column;
    }
} */
/* ********* */
/* advantage */
/* ********* */


/* *********************** */
/* Product Range Available */
/* *********************** */
.home-slider {
    height: 90vh;
}

.home-slider .swiper {
    z-index: 0;
    height: 100%;
}

.home-product-range {
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.home-product-range p {
    margin: 0;
}

.size-guide-product {
    width: 100%;
    height: 100%;
    z-index: 999;
    position: absolute;
}
.size-guide-product p {
    position: absolute;
    bottom: 20px;
    right: 55px;
    color: white;
}

/* .home-slider .swiper .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column-reverse;
    z-index: 10;
}

.home-slider .swiper .swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    left: auto;
    right: 0;
    width: auto;
}






@media only screen and (max-width: 875px) {
    .home-slider .swiper .swiper-pagination {
        flex-direction: row;
    }
}

@media only screen and (max-width: 576px) {
    .home-slider .swiper .swiper-pagination {
        flex-direction: row;
    }
    .home-slider .swiper .swiper-pagination-bullet {
        padding: 0.8rem;
    }
    .home-slider .swiper .swiper-pagination-bullet-active {
        padding: 1.1rem ;
    }
} */


.swiper-pagination-bullet {
    /* display: flex; */
    background: var(--primary-yellow);
    padding: 1rem;
    font-size: 16px;
    color: black;
    /* justify-content: center; */
    /* align-items: center; */
}
.swiper-pagination-bullet-active {
    /* box-shadow: 0px 0px 5px 5px #6d5c49; */
    color: #fff;
    /* padding: 1.2rem ; */
    font-weight: 700;
}

.swiper-pagination > div > p {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-pagination-vertical.swiper-pagination-bullets, .swiper-vertical>.swiper-pagination-bullets {
    top: auto;
    right: 20px;
    bottom: 70px;
    transform: translate3d(0px,-50%,0);
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 60px;
    left: auto;
    right: 20px;
    width: 100%;
}

.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: auto;
    right: 20px;
    transform: none;
    white-space: nowrap;
    height: 50px;
    display: flex;
    align-items: center;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(0.6);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(0.8);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    transform: scale(1.2);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(.8);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: scale(.6);
}
/* *********************** */
/* Product Range Available */
/* *********************** */

/* *************** */
/* rudra advantage */
/* *************** */
.rudra-member {
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../assets/rudra-advantage.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.rudra-desktop, .dealership-desktop {
    display: flex !important;
}

.rudra-mobile, .dealership-mobile {
    display: none !important;
}

.member-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.rudra-member .img-container {
    width: 70px;
    height: 70px;
    padding: 10px;
    background-color: #fff;
    border-radius: 50%;
}

.rudra-member form, .dealership .section-4 form, .contact-us-form {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px grey;
    /* max-width: 450px;
    width: 100%; */
}

.rudra-member-submit-btn {
    border: none !important;
    border-radius: 20px !important;
    color: #fff !important;
    font-weight: 700 !important;
    background-color: var(--primary-yellow) !important;
}

@media only screen and (max-width: 992px) {
    .rudra-member {
        height: auto;
    }
    .rudra-desktop, .dealership-desktop {
        display: none !important;
    }
    .rudra-mobile, .dealership-mobile {
        display: flex !important;
    }
    .rudra-member .member-item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    /* .rudra-member .img-container {
        width: 50px;
        height: 50px;
    } */
}

/* rudra member css */
/* .rudra-member {
    margin-top: -4px;
    background-image: url('../assets/rudra-advantage.jpg');
    background-position: center;
    background-size: cover;
    padding: 40px 0px 40px 0px;
    height: 90vh;
    display: flex;
}

.rudra-member .mobile {
    display: none;
}

.rudra-member .main-row {
    max-width: 1300px;
    width: 1300px;
    margin: auto;
}

.rudra-member .upper-heading,
.rudra-member .options {
    padding: 40px 0px;
}

.rudra-member .main-row .col-md-7 {
    display: flex;
    justify-content: end;
}

.rudra-member form {
    background: #fff;
    border-radius: 10px;
    padding: 40px 20px;
    max-width: 450px;
    width: 100%;
    box-shadow: 0px 0px 10px grey;
    margin-right: 40px;
}

.rudra-member form .form-group {
    margin-bottom: 15px;
}

.rudra-member form .btn {
    width: 100%;
    background-color: var(--primary-yellow);
    color: #fff;
    font-weight: 700;
    border-radius: 20px;
    border: none;
}

.rudra-member form .btn:active {
    background-color: var(--primary-yellow);
}

.rudra-member .options,
.dealership .section-2 .options .items {
    display: flex;
    justify-content: space-between;
}

.rudra-member .options .img-container,
.dealership .section-2 .options .img-container {
    width: 70px;
    height: 70px;
    padding-top: 10px;
    background-color: #fff;
    border-radius: 50%;
    margin: auto;
}

.rudra-member .options img,
.dealership .section-2 .options img {
    width: 50px;
    height: 50px;
    margin: auto;
    display: block;
} */

/* ipad responsive css */

/* @media (max-width: 820px) {
    .rudra-advantage h3 {
        padding-left: 30px;
    }

    .rudra-member .main-row .desktop {
        display: none;
    }

    .rudra-member {
        padding: 40px 30px;
    }

    .rudra-member .row>*,
    .rudra-member .upper-heading {
        padding: 0;
    }

    .rudra-member form {
        margin: 50px auto;
    }

    .rudra-member .main-row .col-md-7 {
        justify-content: center;
    }

    .rudra-member .options .item p {
        font-size: 12px;
        text-align: center;
    }

    .rudra-member .options .img-container {
        width: 60px;
        height: 60px;
        margin: 0px auto 15px;
    }
} */

/* device max-width 600px */

/* @media (max-width: 600px) {
    .rudra-member .mobile {
        display: block;
    }

    .rudra-member .main-row .desktop {
        display: none;
    }

    .rudra-member .upper-heading {
        padding: 0px;
    }

    .rudra-member form {
        margin: 50px auto;
    }

    .rudra-member .main-row .col-md-7 {
        justify-content: center;
    }
} */

/* Mobile Responsive css */

/* @media (max-width: 490px) {

    .rudra-advantage {
        background-image: url('../assets/Mobile section 2 Bg.png');
        height: 440px;
        display: block;
    }

    .rudra-advantage h3 {
        padding: 15px;
    }

    .rudra-advantage .desktop {
        display: none;
    }

    .rudra-advantage .mobile {
        padding: 14px 20px;
    display: block;
    }

    .rudra-advantage .mobile table {
        margin-top: 30px;
    }

    .rudra-advantage .mobile table tr:last-child td:first-child div.item {
        padding-bottom: 20px;
    }

    .rudra-advantage .mobile img {
        width: 100%;
        margin-top: -86px;
        height: 440px;
        padding: 0px 0px;
    }

    .rudra-advantage .mobile .item {
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .rudra-advantage .mobile .item .text-align-end {
        text-align: end;
    }

    .rudra-advantage .upper-circle {
        margin: 20px 10px;
    }

    .rudra-member {
        height: auto !important;
        padding: 40px 20px;
    }

    .rudra-member .main-row {
        width: 100%;
    }

    .rudra-member .row,
    .rudra-member .row>*,
    .about-us .section-1 .row .col-12:nth-child(2) {
        margin: 0px !important;
        padding: 0px !important;
    }

    .rudra-member .upper-heading {
        padding: 0px;
    }

    .rudra-member .main-row .col-md-7 {
        display: block;
    }

    .rudra-member form {
        margin: 50px auto 10px;
        padding: 20px;
    }

    .rudra-member form .form-group {
        margin-bottom: 10px;
    }

    .rudra-member .main-row .desktop {
        display: none;
    }

    .rudra-member .options .item {
        width: 200px;
    }

    .rudra-member .options img {
        width: 40px;
        height: 40px;
    }
} */