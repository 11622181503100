/* -------------------------------------------- */

/* Investor Relationship CSS */

.investor-relationship {
    padding-top: 10vh;
    /* height: 100vh; */
}

.investor-relationship .row .col-3 {
    padding: 25px 25px 0px 40px;
}

.investor-relationship .row .col-3 .toggle-btn {
    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
}

.investor-relationship .row .col-3 ul li {
    list-style: none;
    font-size: 19px;
    padding: 15px 0px;
    /* transition: .8s; */
}

.investor-relationship .row .col-3 ul li:hover {
    color: var(--primary-yellow);
    /* font-weight: 600; */
}

.investor-relationship .row .main-section {
    background-color: var(--primary-blue);
    padding-top: 3rem;
    padding-bottom: 3rem;
    height: 90vh;
    /* padding: 20px 40px 0px 40px; */
    /* height: 95vh; */
}
.investor-relationship .row .main-section .result-container {
    height: 75vh;
    overflow: scroll;
}

.investor-relationship .row .col-9 {
    text-transform: uppercase;
    text-align: center;
}

.investor-relationship .row .col-9 .slider-section {
    width: 100%;
    background-color: #fff;
    height: max-content;
    min-height: 45px;
    border-radius: 10px;
    max-width: 950px;
    margin: 40px auto 30px auto;
}

.investor-relationship .row .col-9 .slider-section .swiper-wrapper {
    padding: 0px 10px;
}

.investor-relationship .row .col-9 .slider-section .swiper-slide {
    margin-top: 10px;
    min-width: fit-content;
}

.investor-relationship .row .col-9 .slider-section .swiper-slide:hover {
    color: var(--primary-yellow);
    /* font-weight: 700; */
}

/* .investor-relationship .row .result {
    justify-content: space-between;
    max-width: 950px;
    margin: auto;
} */

.investor-relationship .row .result .col-5 {
    background-color: #fff;
    margin: 30px 30px;
    text-align: justify;
    height: 100px;
    border-radius: 10px;
    text-decoration: none;
}

.investor-relationship .row .result .col-5 a {
    text-decoration: none;
    color: var(--primary-blue);
}

.investor-relationship .row .result .col-5:hover {
    background-color: #ffdaaf;
    color: #fff !important;
}

.investor-relationship .row .result .col-5 h5 {
    margin: auto;
    width: fit-content;
    padding-top: 35px;
}

.investor-relationship .slider .is-active,
.investor-relationship ul li.is-active  {
    color: var(--primary-yellow);
}
.swiper-button-next:after, .swiper-button-prev:after {
    margin-top: 9px;
    font-size: 20px;
    font-weight: 600;
    color: var(--primary-yellow);
    text-transform: none!important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 1;
}

@media (max-width: 820px) {
    .investor-relationship .row .col-3 {
        padding: 25px 15px 0px 30px;
    }

    .investor-relationship .row .col-3 ul,
    .investor-relationship .row .col-3 .toggle-btn {
        padding-left: 0px;
    }
}

@media only screen and (max-width: 576px) {
    .investor-relationship {
        padding-top: 10vh;
    }
}

/* @media only screen and (max-width: 992px) {
    .investor-relationship {
        height: auto;
    }
} */

.accordion-button:not(.collapsed) {
    color: #000 !important;
    background-color: #fff !important;
}

.accordion-button:focus {
    /* border-color: #000 !important; */
    box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon) !important;
}

.result .card {
    transition: .4s all;
}

.result a {
    text-decoration: none;
    color: #000;
}

.result .card:hover {
    background-color: #ffdaaf;
    color: #000;
}

/* slider */

.swiper-wrapper {
    align-items: center;
}

.investor-relationship .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}