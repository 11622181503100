.about-us {
    padding-top: 10vh;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-us-title {
    font-weight: 700 !important;
    color: var(--primary-yellow);
}

@media only screen and (max-height: 786px) {
    .about-us p {
        font-size: 14px;
    }
}

@media only screen and (max-width: 576px) {
    .about-us p {
        font-size: 12px;
    }
}

@media only screen and (max-width: 786px) {
    .about-us p {
        font-size: 14px;
    }
}


.company-strength {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.company-strength .card {
    padding: 20px 0 10px 0;
    border-radius: 10px;
    box-shadow: 0 0 10px 3px #d0d0d0;
    transition: .4s;
}

.company-strength .card:hover {
    box-shadow: 0 0 20px 6px #d0d0d0;
}

.company-strength .card .card-title {
    font-weight: 700;
    color: var(--primary-yellow);
    text-align: center;
}

.company-strength .card .card-body {
    text-align: justify;
}

.company-strength img {
    height: 80px;
    width: 80px;
    margin: auto;
}

@media only screen and (max-width: 768px) {
    /* .about-us {
        height: auto;
    } */
}
@media only screen and (max-width: 768px) {
    .about-us {
        height: auto;
        padding: 20vh 0 10vh;
    }
}

@media only screen and (max-width: 1200px) {
    .company-strength {
        height: auto;
    }
}



.our-team {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.our-team p {
    text-align: justify;
}

.our-team-bg {
    background: url(../assets/blue\ bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.our-team-bg .our-team-info {
    flex-direction: row !important;
}

.our-team .our-team-info {
    flex-direction: row-reverse;
}

.our-team .our-team-info {
    /* justify-content: center; */
    align-items: center;
}

.our-team-bg .our-team-info {
    /* justify-content: center; */
    align-items: center;
    color: #fff;
}

.team-name {
    color: var(--primary-yellow);
}
.our-team .our-team-bg .our-team-info .team-role {
    color: #fff;
}

@media only screen and (max-width: 768px) {
    .our-team {
        height: auto;
    }
    .our-team img {
        height: 500px;
    }
}
@media only screen and (max-width: 576px) {
    .our-team img {
        height: auto;
    }
}





.our-mission {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../assets/blue\ bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.our-mission img {
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.our-mission .images-section div:nth-child(1) {
    height: 200px;
}
.our-mission .images-section div:nth-child(2) {
    margin-left: -0.5rem;
    height: 250px;
}
.our-mission .images-section div:nth-child(3) {
    margin-top: -2rem;
    height: 250px;
}
.our-mission .images-section div:nth-child(4) {
    margin-left: -0.5rem;
    height: 200px;
}

@media only screen and (max-width: 768px) {
    .our-mission {
        height: auto;
    }
}

/* @media only screen and (max-width: 768) {
    .our-mission {
        height: auto;
    }
    .our-mission .images-section div:nth-child(1) {
        height: 100px;
    }
    .our-mission .images-section div:nth-child(2) {
        height: 150px;
    }
    .our-mission .images-section div:nth-child(3) {
        margin-top: -40px;
        height: 150px;
    }
    .our-mission .images-section div:nth-child(4) {
        height: 100px;
    }
} */



.timeline {
    /* height: 90vh; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.timeline .card {
    border: 0 !important;
}

.btn-yellow {
    background-color: #ed9e41;
    background-color: var(--primary-yellow);
    border-radius: 5px;
    color: #fff;
    font-weight: 700;
    margin-bottom: 17px;
    padding: 8px 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

@media only screen and (max-width: 1200px) {
    .timeline {
        height: auto;
    }
}