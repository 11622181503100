.dealership .section-1 {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dealership .section-1 img, .dealership .section-3 img {
    border-radius: 10px;    
}
.dealership .section-2 {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../assets/rudra-advantage.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.dealership .section-2 .member-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dealership .section-2 .img-container
 {
    width: 70px;
    height: 70px;
    padding: 10px;
    background-color: #fff;
    border-radius: 50%;
}

.dealership .section-3 {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dealership .section-3 img {
    width: 100%;
    height: 100%;
}
.dealership .section-3  ul 
{
    margin: 0;
    padding-left: 1.3rem;
}
.dealership .section-3 ul.dashed-bullets 
{
    list-style-type: none;
}
.dealership .section-3 ul.dashed-bullets > li 
{
    text-indent: -20px;
}
.dealership .section-3 ul.dashed-bullets > li:before 
{
    content: "-";
    text-indent: -20px;
    padding-right : 15px;
}


.dealership .section-4 {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('../assets/rudra-advantage.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width: 991px) {
    .dealership .section-1 {
        height: auto;
        padding-top: 12vh;
    }
    .dealership .section-4 {
        height: auto;
    }
}

@media screen and (max-width: 990px) {
    .dealership .section-3 {
        height: auto;
    }
}