:root {
    --primary-yellow: #ED9E41;
    --primary-blue: #212C43;
    --white: #fff;
    --red: #cf1414;
    --main-heading: 3.5em;
    --heading: 2.188em;
    --sub-heading: 1.5em;
    --bold-normal-text: 1.25em;
    --normal-text: 1.025em;
    --ipad-main-heading: 60px;
    --ipad-heading: 32px;
    --ipad-sub-heading: 24px;
    --ipad-bold-normal-text: 20px;
    --ipad-normal-text: 14px;
    --mb-main-heading: 1.5em;
    --mb-heading: 1.315em;
    --mb-sub-heading: 1.125em;
    --mb-bold-normal-text: 1.025em;
    --mb-normal-text: 0.875em;
    --bs-navbar-toggler-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.text-yellow {
    color: var(--primary-yellow);
}
.text-blue {
    color: #2c51a3;
}
.h-90vh {
    height: 90vh;
}
.fw-400 {
    font-weight: 400 !important;
}

.text-justify {
    text-align: justify;
}

a {
    text-decoration: none;
}

#navbarMain {
    /* display: none; */
    /* opacity: 0; */
    position: fixed;
	top: 0;
	width: 100%;
    height: 10vh;
	background-color: transparent;
    color: #fff !important;
    z-index: 999;
    transition: all 0.5s;
}
#navbarMain a.nav-link {
    text-decoration: none !important;
    color: #000;
}
.home-header a.nav-link {
    color: #fff !important;
}
.home-header a.nav-link {
    color: #fff !important;
}

.home-header .show a.nav-link {
    color: #000 !important;
}

.home-header .navbar-toggler-icon {
    background-image: var(--bs-navbar-toggler-icon);
}

#navbarMain a.nav-link.active {
    text-decoration: none !important;
    color: var(--primary-yellow);
}
#navbarMain .nav-link {
    color: #fff;
}
.is-sticky {
    -webkit-animation: fadeInDown .5s ease-in-out 0s 1 normal none running;
    animation: fadeInDown .5s ease-in-out 0s 1 normal none running;
    background-color: #fff !important;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    color: #212c43!important;
    height: 90px;
    left: 0;
    padding: 25px 50px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}
.is-sticky .nav-link {
    color: #000 !important;
}
#navbarMain img {
    height: 30px;
}

@media only screen and (max-width: 768px) {
    #navbarMain img {
        height: 20px;
    }
}

.offset-navbar-img {
    height: 60px !important;
}
.navbar-toggler {
    border: none !important;
}
.navbar-toggler:hover {
    border: none !important;
}
.navbar-toggler:focus {
    border: none !important;
    box-shadow: none !important;
}
.navbar-nav .nav-link.active {
    color: var(--primary-yellow) !important;
}
.navbar-nav .nav-link:hover {
    color: var(--primary-yellow) !important;
}
.dropdown-item.active {
    background-color: var(--primary-yellow) !important;
}



/* Header Css */

.header {
    display: flex;
    justify-content: space-between;
    padding: 25px 50px;
}

.header {
    padding: 25px 20px;
}

/* .is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    height: 100px;
    width: 100%;
    z-index: 1;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #ffffff !important;
    color: var(--primary-blue) !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding: 25px 50px;
} */

.header .brand img {
    width: 150px;
    height: auto;
}

.header nav ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.header nav ul li {
    list-style: none;
    font-size: 1.125em;
}

.header nav ul li a {
    color: var(--primary-blue);
    padding: 10px 20px;
    text-decoration: none;
}

.header nav ul li:hover a,
.header nav ul li a.active {
    color: var(--primary-yellow);
}

.header li.icon {
    display: none;
}

@media (max-width: 1150px) {
    .header li.icon {
        display: block;
    }

    .header li {
        display: none;
    }
}




/* ----------------------------------------- */

/* footer css */

/* .footer {
    max-width: 1300px;
    margin: auto;
    padding: 50px 50px;
} */

.text-justify {
    text-align: justify;
}

/* .footer .row div:first-child {
    padding-right: 10%;
} */

.footer img {
    margin-right: 30px;
    width: 40px;
    justify-content: space-between;
}

.get-in-touch {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.social-media-icons {
    display: flex;
    justify-content: center;
}

.footer h3,
.footer h2 {
    font-weight: bold;
}

.footer .row  a {
    text-decoration: none;
    color: var(--primary-blue);
}

.footer .whatsapp-icon {
    position: fixed;
    width: 50px;
    height: 50px;
    background-color: var(--primary-blue);
    color: #FFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    /* box-shadow: 0px 0px 5px #dbdadb; */
    z-index: 100;
}

.footer .whatsapp-icon a {
    text-decoration: none;
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* min-width: 820px and max-width: 1024px */


/* --------------------------------------------- */




/* ipad responsive css */

@media (max-width: 820px) {

    .get-in-touch {
        display: flex;
        align-items: flex-start !important;
    }


    .footer .row,
    .footer .row .col-12:last-child {
        margin: 0;
        padding: 0;
    }

    .footer .row .col-12:nth-child(1),
    .footer .row .col-12:nth-child(2) {
        margin-bottom: 30px;
    }
    
}

/* -------------------------------------------- */


/* --------------------------------------------- */

.footer .col-md-7 {
    margin-bottom: 30px;
}

.footer .mb-d-text {
    margin-top: 40px !important;
}

.footer .whatsapp-icon {
    bottom: 20px;
    right: 20px;
}

/* -------------------------------- */