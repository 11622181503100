/* Contact Us CSS */

.contact-us {
    margin-top: 10vh;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-us-maps {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .contact-us-maps .card:nth-child(1) {
    height: 306px;
} */
.contact-us-maps .card {
    height: 450px;
}
/* .contact-us-maps .card:nth-child(3) {
    height: 450px;
} */

.contact-header {
    background-image: url('../assets/white-bg.png') !important;
    background-size: 100vh;

}

.contact-us form {
    background: #fff;
    border-radius: 10px;
    padding: 40px 20px;
    max-width: 500px;
    margin-right: -5%;
    position: relative;
    right: -51%;
}

.contact-us form .form-group {
    margin-bottom: 15px;
}

.contact-us form .btn {
    width: 100%;
    background-color: var(--primary-yellow);
    color: #fff;
    font-weight: 700;
    border-radius: 20px;
    border: none;
}

.contact-us .info-section {
    background-color: var(--primary-blue);
    color: #fff;
    padding: 50px 200px 0px 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact-us .info-section img {
    margin-right: 30px;
    width: 40px;
    justify-content: space-between;
}

.marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25px;
    height: 25px;
    transform: rotate(-45deg);
    background-color: #cf1414;
    border: 2px solid #fff;
    border-radius: 50% 50% 50% 0;
    user-select: none;
    margin: -20px 0 0 -20px;
}

.contact-us .section-2 {
    padding: 40px 30px 200px 30px;
}

.contact-us .section-2 .col-12 {
    border-radius: 20px;
    width: 33%;
    height: 50vh;
}

.contact-us .section-2 .col-12 h3 {
    padding: 15px 0px;
}

.contact-us .section-2 .col-12 > div > div {
    border-radius: 10px;
}

@media (max-width: 1650px) {
    .contact-us form {
        right: -40%;
    }
}

@media (max-width: 1300px) {
    .contact-us form {
        right: -35%;
    }
}

@media (max-width: 992px) {
    .contact-us {
        height: auto;
    }
    .contact-us-maps {
        height: auto;
    }
    .contact-us-maps .card {
        height: 350px;
    }
    .contact-us form {
        top: 0;
        right: 0;
        margin: 50px auto;
    }
}

@media (width: 992px) {
    .contact-us {
        height: 90vh;
    }
    .contact-us-maps {
        height: 90vh;
    }
    .contact-us form {
        right: -35%;
    }
}

@media (max-width: 700px) {
    .contact-us .section-2 .col-12 {
        width: 100% !important;
    }

    .contact-us .section-2 .col-12:nth-child(2),
    .contact-us .section-2 .col-12:nth-child(3) {
        margin-top: 168px;
    }
}

@media (max-width: 510px) {
    .contact-us form {
        margin: 30px 3% 40px 3%;
    }

    .contact-us .info-section {
        padding: 74px 40px 100px 40px;
    }
}