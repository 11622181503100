.tmt-product section {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}



/* .tmt-product .section-1 {
    padding-top: 15vh;
} */

.tmt-product .home-tmt-product {
    padding-top: 10vh;
    height: 100vh;
}

.tmt-product .home-tmt-product .card {
    border-radius: 15px;
    box-shadow: 1px 1px 5px #c4c3c3;
    transition: .4s;
}

.tmt-product .home-tmt-product .card:hover {
    box-shadow: 0 0 7px 3px #d0d0d0;
}

.tmt-product .home-tmt-product .card .card-title {
    color: var(--primary-yellow);
}

.tmt-product .home-tmt-product .card .bullet-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tmt-product .home-tmt-product .card .card-text {
    text-align: justify;
}

.tmt-product .home-tmt-product .bullet {
    background-color: var(--primary-yellow);
    border-radius: 50%;
    /* margin: 4px 4px 4px 0; */
    height: 17px;
    width: 17px;
}

.banner-section {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 991px) {
    .tmt-product section {
        height: auto;
    }
    .tmt-product .home-tmt-product {
        padding-top: 10vh;
        height: auto;
    }
    .tmt-product .section-2 {
        height: auto !important;
    }
}

/* section 4 */
.yellow-text {
    color: #ed9e41;
}

.info-container {
    background: url('../assets/MicrosoftTeams-image\ \(19\).png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
}

.fw-300 {
    font-weight: 300;
}

/* .info p {
    margin-left: 20px;
} */

.licence-img {
    width: 80px;
    height: 80px;
}

.licence-mobile {
    display: none;
}

@media screen and (max-width: 991px) {
    .info-container {
        height: auto;
    }
    .licence {
        display: none;
    }
    .licence-mobile {
        display: flex;
        justify-content: flex-start;
    }
    .licence-mobile .licence-img {
        width: 40px;
        height: 40px;
    }
    .licence-mobile .licence-img:last-child {
        margin-right: 1rem;
    }
}

@media screen and (max-width: 769px) {
    /* .licence {
        display: flex;
        justify-content: flex-end;
    } */
    .d-sm-flex {
        display: flex;
        flex-direction: column-reverse;
    }
    .pl-25 {
        padding-left: 2.5rem;
    }
}

@media screen and (max-width: 992px) {
    .info-container {
        background: url('../assets/992pxbg.png');
        background-size: cover;
        background-repeat: no-repeat;
        height: auto;
    }
}

@media screen and (max-width: 768px) {
    .info-container {
        background: url('../assets/111.png');
        background-size: cover;
        background-repeat: no-repeat;
        height: auto;
    }
    .d-sm-flex {
        display: flex;
        flex-direction: column-reverse;
    }
    .licence-mobile {
        display: flex;
        justify-content: flex-end;
    }
    .specs {
        margin-top: 310px !important;
    }
    /* .info p {
        margin-left: 20px;
    } */
    .pl-23 {
        padding-left: 2.3rem;
    }
    .footer-text {
        text-align: justify;
    }
}

@media screen and (max-width: 700px) {
    .specs {
        padding-top: 240 !important;
    }
}

@media screen and (max-width: 576px) {
    .licence-mobile {
        display: flex;
        justify-content: flex-start;
    }
    /* .info-container .rod {
        background: url(../../src/assets/tmtbar.png);

    } */
}




/* ******** */
/* old code */
/* ******** */


/* tmt-product css */

.tmt-product .section-1 .row,
.tmt-product .section-2 .row,
.tmt-product .section-3 .row {
    max-width: 1400px;
    margin: auto;
}

.tmt-product .section-1 {
    padding-top: 100px;
}

.tmt-product .section-1 img {
    width: 100%;
}

.tmt-product .section-1 .headings {
    margin: 70px auto;
    justify-content: space-around;
}

.tmt-product .section-1 .headings .item {
    max-width: 320px;
    width: 100%;
    box-shadow: 1px 1px 5px rgb(196, 195, 195);
    border-radius: 15px;
    height: 200px;
    padding: 20px 18px;
    margin-bottom: 20px;
    transition: 0.4s;
}

.tmt-product .section-1 .headings .item:hover {
    box-shadow: 0 0 7px 3px #d0d0d0;
}

.tmt-product .section-1 .headings .item .bullet {
    max-width: 15px;
    max-height: 15px;
    background-color: var(--primary-yellow);
    border-radius: 25px;
    flex-basis: 20%;
    margin: 4px 4px 4px 0px;
}

.tmt-product .section-2 {
    background-image: url('../assets/blue\ bg.png');
    display: flex;
    height: 90vh;
    flex-direction: column;
    justify-content: center;
}

.tmt-product .section-2 .card {
    box-shadow: 0px 0px 10px #dbdadb;
    padding: 100px 40px;
    max-width: 550px;
    border-radius: 15px;
}

.tmt-product .section-2 .card .c-blue {
    color: var(--primary-blue);
}

.tmt-product .section-2 .card .c-yellow {
    color: var(--primary-yellow);
}

.tmt-product .section-2 .timeline-heading {
    padding-left: 70px;
    margin-bottom: 20px;
}

.tmt-product .section-2 ul {
    color: #fff;
}

.tmt-product .section-2 ul li {
    list-style: none;
    margin-bottom: 30px;
}

.tmt-product .section-2 ul li .main-container {
    position: relative;
    width: 500px;
}

.tmt-product .section-2 ul li .main-container .not-li {
    padding-left: 40px;
}

.tmt-product .section-2 ul li .main-container::after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    margin-left: -3px;
}

/*Adjusting box of all content*/
.tmt-product .section-2 ul li .text-wrapper {
    padding: 10px 40px;
    position: relative;
    box-sizing: border-box;
    margin: 20px 0;
    text-align: justify;
}

.tmt-product .section-2 ul li .text-wrapper:nth-child(1)::after {
    display: none !important;
}
.tmt-product .section-2 ul li .text-wrapper:nth-child(1) {
    padding-left: 0;
}
 
.tmt-product .section-2 ul li .text-wrapper::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: -11px;
    background-color: #fff;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

.tmt-product .section-3 {
    padding: 80px 60px;
}

.tmt-product .section-3 h1 {
    max-width: 1400px;
    margin: auto;
}

.tmt-product .section-3 img {
    width: 100%;
}

.tmt-product .section-3 .boxer-4d {
    margin-top: 50px;
}

.tmt-product .section-3 .tmt-bar {
    margin-top: 100px;
}

.tmt-product .section-3 .tmt-bar a,
.tmt-product .section-3 .boxer-4d a {
    text-decoration: none;
    background-color: var(--primary-blue);
    padding: 5px 15px;
    border-radius: 5px;
}

.tmt-product .section-3 .tmt-bar a:hover,
.tmt-product .section-3 .boxer-4d a:hover {
    color: var(--white);
}

.tmt-product .section-3 .col-7 img {
    height: 450px;
    object-fit: contain;
}

.tmt-product .section-3 .row .d-flex img {
    width: 30px;
    object-fit: contain;
    margin-right: 10px;
}

.tmt-product .section-4 {
    height: 90vh;
    display: flex;
    max-width: 1300px;
    margin: auto;
    flex-direction: column;
    justify-content: center;
}

.tmt-product .section-4 img {
    width: 100%;
}

.tmt-product .section-5 {
    background-image: url('../assets/MicrosoftTeams-image\ \(19\).png');
    padding: 50px 30px 20px 30px;
    background-size: cover;
}

.tmt-product .section-5 .main-heading,
.tmt-product .section-5 .row {
    max-width: 1400px;
    margin: auto;
}

.tmt-product .section-5 .row {
    padding: 50px 0px 20px 0px;
}

.tmt-product .section-5 .mb-d-none {
    margin-top: 10px;
    max-width: 1400px;
    margin: auto;
}

.tmt-product .section-5 .row .icons {
    margin-top: 25%;
}

.tmt-product .section-5 .mb-d-flex,
.tmt-product .section-5 .mb-d-text {
    display: none !important;
}

.tmt-product .section-5 .icons img {
    width: 80px;
}

.tmt-product .section-5 .icons img:nth-child(2) {
    width: 80px;
    height: 80px;
    margin-left: 110px;
}

.tmt-product .section-5 .row .col-12 .c-yellow {
    text-transform: uppercase;
    padding-top: 40px;
}

.tmt-product .section-5 .row .col-12 table {
    margin-top: -37px;
}

.tmt-product .section-5 .row .col-12 table td {
    vertical-align: baseline;
}

.tmt-product .section-5 .row .col-12 table .bold-text {
    padding-left: 50px;
}


@media (max-width: 990px) {
    .tmt-product .section-2 .col-12:nth-child(2) {
        margin-top: 60px;
    }

    .tmt-product .section-4 {
        padding: 100px 30px;
    }

    .tmt-product .section-4 img {
        width: 100%;
    }
}

@media (max-width: 700px) {
    .tmt-product .section-1 div.advantage-header {
        padding: 0px 10%;
    }
}

@media (max-width: 450px) {

    .tmt-product .section-2 {
        padding: 80px 20px;
    }

    .tmt-product .section-2 ul {
        padding: 0px;
    }

    .tmt-product .section-2 ul li .main-container {
        width: 100%;
    }

    .tmt-product .section-2 ul li .main-container .not-li {
        padding-left: 20px;
    }

    .tmt-product .section-2 ul li .text-wrapper {
        padding: 10px 20px;
    }



}

/* ----------------------- */



@media (max-width: 1366px) {
    .tmt-product .section-5 .row .col-12 table {
        margin-left: -20px;
    }

    .tmt-product .section-5 .row .col-12 table .body-text-bold,
    .tmt-product .section-5 .row .col-12 table .body-text {
        font-size: 26px;
    }

    .tmt-product .section-5 .row .icons img {
        width: 11%;
        object-fit: contain;
    }

    .tmt-product .section-5 .row .col-12 .future {
        padding-top: 7px;
    }
}

@media (max-width: 1280px) {
    
    .tmt-product .section-5 .row .icons {
        margin-top: 100px;
    }

    .tmt-product .section-5 .row .icons img {
        width: 15%;
        object-fit: contain;
    }
}

/* min-width: 820px and max-width: 1024px */

@media (min-width: 820px) and (max-width: 1024px) {

    .tmt-product .section-1 img {
        width: 580px;
    }

    .tmt-product .section-1 .headings .item {
        max-width: 360px;
        height: 180px;
    }

    .tmt-product .section-1 .headings .item .bullet {
        margin: 3px 8px;
    }

    .tmt-product .section-2 {
        padding: 80px 40px;
    }

    .tmt-product .section-2 .row {
        align-items: center;
    }

    .tmt-product .section-2 .card {
        padding: 40px;
    }

    .tmt-product .section-2 ul li .main-container {
        width: 100%;
    }

    .tmt-product .section-3 {
        padding: 80px 40px;
    }

    .tmt-product .section-4 {
        padding: 100px 40px;
    }

    .tmt-product .section-4 img {
        width: 90%;
        display: block;
        margin: auto;
    }

    .tmt-product .section-5 {
        background-position: center;
        padding: 20px 40px;
    }

    .tmt-product .section-5 .row .col-12 table .body-text-bold,
    .tmt-product .section-5 .row .col-12 table .body-text {
        font-size: 22px;
    }

    .tmt-product .section-5 .row .icons img {
        width: 13%;
    }

    .tmt-product .section-5 .icons img:nth-child(2) {
        margin-left: 70px;
    }

    .tmt-product .section-5 .row .icons {
        margin-top: 140px;
    }
}

/* --------------------------------------------- */


/* ipad responsive css */

@media (max-width: 820px) {

    .tmt-product .section-1 {
        padding-top: 40px;
    }

    .tmt-product .section-1 img {
        width: 365px;
        display: block;
        margin: auto;
    }

    .tmt-product .section-2 {
        padding: 80px 30px;
    }

    .tmt-product .section-2 .card {
        margin: auto;
    }

    .tmt-product .section-2 .main-container p {
        text-align: justify;
    }

    .tmt-product .section-2 ul li .main-container {
        width: 100%;
    }

    .tmt-product .section-3 {
        padding: 80px 30px 20px;
    }

    .tmt-product .section-3 .row {
        margin: 50px auto 0px;
        align-items: center;
    }

    .tmt-product .section-3 .col-7 img {
        height: 300px;
    }

    .tmt-product .section-3 .tmt-bar {
        margin-top: 0px;
    }

    .tmt-product .section-4 {
        padding: 100px 30px;
    }

    .tmt-product .section-5 {
        background-image: url('../assets/111.png');
        background-position: center;
        padding: 50px 30px;
    }

    .tmt-product .section-5 .row {
        padding: 30px 0px 20px 0px;
    }

    .tmt-product .section-5 .row .col-12:nth-child(2) {
        margin-top: 200px;
    }

    .tmt-product .section-5 .row .col-12 table .bold-text {
        padding-left: 20px;
    }

    .tmt-product .section-5 .sub-heading {
        margin-top: 15px;
    }

    .tmt-product .section-5 .row .col-12 table {
        margin-top: 20px;
    }

    .tmt-product .section-5 .row .icons {
        display: none !important;
    }

    .tmt-product .section-5 .mb-d-none {
        display: none;
    }

    .tmt-product .section-5 .mb-d-flex {
        display: flex !important;
        justify-content: end;
    }

    .tmt-product .section-5 .mb-d-text {
        margin: 42px auto 0px;
        display: block !important;
        width: -moz-fit-content;
        width: fit-content;
    }

    .tmt-product .section-5 .mb-d-flex img {
        width: 81px;
    }

    .tmt-product .section-5 .mb-d-flex img:nth-child(2) {
        width: 80px;
        height: 80px;
        margin-left: 40px;
    }

    .dealership .section-1 .row .col-12 img {
        margin-bottom: 15px;
    }

    .dealership .section-2 .options .items {
        width: 100%;
    }

    .dealership .section-2 .options .items .item:nth-child(4),
    .dealership .section-2 .options .items .item:nth-child(5) {
        display: none;
    }

    .dealership .section-4 .mb-d-none {
        display: none !important;
    }

    .dealership .section-4 .mb-d-text {
        display: block;
    }

    .dealership .section-4 .col-12 .info {
        position: relative;
        bottom: -5%; 
    }

    .media .section-2 > div h1.main-heading {
        padding-top: 0px;
    }    
}


/* -------------------------------------------- */

/* device max-width 600px */

@media (max-width: 600px) {

    /* ------------------------------------ */

    /* tmt product css */

    .tmt-product .section-1 {
        padding-top: 30px;
        margin: auto;
    }

    .tmt-product .section-1 div.advantage-header {
        padding: 0px;
    }

    .tmt-product .section-1 img {
        width: 200px;
    }

    .tmt-product .section-1 .headings {
        margin: 30px auto;
    }

    .tmt-product .section-1 .headings .item {
        padding: 25px 30px;
        width: 310px;
        height: 180px;
    }

    .tmt-product .section-2,
    .tmt-product .section-4 {
        height: auto;
        padding: 50px 20px;
    }

    .tmt-product .section-2 .card {
        padding: 40px 25px;
    }

    .tmt-product .section-2 .timeline-heading {
        padding-left: 0px;
        margin-bottom: 20px;
    }

    .tmt-product .section-2 ul li .text-wrapper::after {
        width: 15px;
        height: 15px;
        left: -10px;
        top: 15px;
    }

    .tmt-product .section-2 ul li .main-container::after {
        width: 1px;
    }

    .tmt-product .section-3 {
        padding: 30px 20px 0px;
    }

    .tmt-product .section-3 .row {
        margin: 0px auto;
    }

    .tmt-product .section-3 .tmt-bar {
        margin-top: 30px;
    }

    .tmt-product .section-3 .boxer-4d {
        margin-top: 50px;
    }

    .tmt-product .section-3 .tmt-bar h2,
    .tmt-product .section-3 .boxer-4d h2 {
        font-size: 16px;
    }

    .tmt-product .section-3 .boxer-4d img {
        width: 100px;
        object-fit: contain;
        margin-bottom: 7px;
    }

    .tmt-product .section-3 .col-7 img {
        height: 220px;
        object-fit: contain;
    }

    .tmt-product .section-3 .row .d-flex img {
        width: 20px;
        object-fit: contain;
        margin-right: 10px;
    }

    .tmt-product .section-3 .row .d-flex h2 {
        font-size: 18px;
        font-weight: 300;
    }

    .tmt-product .section-4 img {
        width: 85%;
        display: block;
        margin: auto;
    }

    .dealership .section-1,
    .dealership .section-3,
    .dealership .section-4 {
        padding: 50px 20px;
        height: auto;
    }

    .dealership .section-2 {
        height: auto;
    }

    .dealership .section-1 .row .col-12:nth-child(2) {
        padding-left: 0px;
    }

    .dealership .section-1 .row .col-12:nth-child(2) .main-heading br {
        display: none;
    }

    .dealership .section-2 .rudra-member {
        padding: 0px 20px;
    }

    .dealership .section-2 .options .img-container {
        width: 50px;
        height: 50px;
        padding-top: 9px;
    }

    .dealership .section-2 .options .item img {
        width: 30px;
        height: 30px;
    }

    .dealership .section-2 .options .items {
        padding: 10px 20px 50px;
    }

    .dealership .section-2 .options .item {
        width: 150px;
    }

    .dealership .section-3 .row .col-12 {
        padding: 0px;
    }

    .dealership .section-3 ul {
        margin-top: 30px;
        padding: 0px 10px;
    }

    .dealership .section-4 {
        background-size: cover;
    }

    .dealership .section-4 .row .col-12 {
        padding: 0px;
    }

    .dealership .section-4 form {
        padding: 30px 20px;
        max-width: 100%;
        margin-right: 0px;
    }

    /* -------------------------------- */

}

@media (max-width: 320px) {
    .tmt-product .section-1 .headings .item {
        max-width: 150px;
    }

    .tmt-product .section-1 .headings .item .bullet {
        height: 6px;
        width: 6px;
        max-width: 6px;
        max-height: 6px;
    }

    .tmt-product .section-1 .headings .item h5 {
        font-size: 12px;
    }

    .rudra-advantage p,
    .tmt-product .section-1 .headings .item p {
        font-size: 10px;
    }

    .tmt-product .section-2 .card h3,
    .tmt-product .section-2 .card h1,
    .tmt-product .section-2 .card h1.c-blue {
        font-size: 24px;
    }

    .tmt-product .section-2 .timeline-heading h4,
    .tmt-product .section-5 .heading {
        font-size: 24px;
    }

    .tmt-product .section-2 ul li .main-container .text-wrapper h6 {
        font-size: 16px;
    }
}

/* -------------------------------------------- */

@media (min-width: 600px) and (max-width: 1400px) and (max-height: 660px) {
    .tmt-product .section-1 {
        height: 90vh;
        padding: 50px 10px 0px;
    }

    .tmt-product .section-2 {
        padding: 50px 60px;
    }

    .tmt-product .section-2 .row {
        align-items: center;
    }

    .tmt-product .section-2 ul li .text-wrapper {
        margin: 10px 0;
    }

    .tmt-product .section-4 {
        padding: 100px 30px;
    }

    .tmt-product .section-4 img {
        width: 90%;
        display: block;
        margin: auto;
    }
}